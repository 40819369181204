<template>
    <div>
        <recess-divider variant="large" show-line />

        <div class="row">
            <div class="col-12 d-flex justify-content-between">
                <h2 class="qa-user-table-title">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.Title }}</h2>
            </div>
        </div>

        <form @submit.prevent="submitHandler">
            <div class="row">
                <div class="col-lg-4 qa-order-customer-gender">
                    <h5 class="qa-order-customer-gender-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.Gender.Label }}</h5>
                    <p class="qa-order-customer-gender-value">{{ order.customer.gender ? order.customer.gender : '-' }}</p>
                </div>
                <div class="col-lg-4 qa-order-customer-initials">
                    <h5 class="qa-order-customer-initials-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.Initials.Label }}</h5>
                    <p class="qa-order-customer-initials-value">{{ order.customer.initials ? order.customer.initials : '-' }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 qa-order-customer-first-name">
                    <h5 class="qa-order-customer-first-name-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.FirstName.Label }}</h5>
                    <p class="qa-order-customer-first-name-value">{{ order.customer.firstName ? order.customer.firstName : '-' }}</p>
                </div>

                <div class="col-lg-4 qa-order-customer-address-street">
                    <h5 class="qa-order-customer-address-street-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.Street.Label }}</h5>
                    <p class="qa-order-customer-address-street-value">{{ order.customer.address.street ? order.customer.address.street : '-' }}</p>
                </div>
                <div class="col-lg-4">
                    <recess-input
                        :value="order.customer.birthCity"
                        :label-text="PROVIDER.Order.OrderDetails.EditStudentDetails.BirthCity.Label"
                        :readonly="!allowEditingOrderStatus"
                        class="qa-order-customer-birth-city"
                        @input="(newValue) => setCustomerBirthCity(newValue ? newValue : null)"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 qa-order-customer-middle-name">
                    <h5 class="qa-order-customer-middle-name-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.MiddleName.Label }}</h5>
                    <p class="qa-order-customer-middle-name-value">{{ order.customer.middleName ? order.customer.middleName : '-' }}</p>
                </div>
                <div class="col-lg-4 qa-order-customer-address-house-number">
                    <h5 class="qa-order-customer-address-house-number-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.HouseNumber.Label }}</h5>
                    <p class="qa-order-customer-address-house-number-value">{{ order.customer.address.houseNumber ? order.customer.address.houseNumber : '-' }}</p>
                </div>
                <div class="col-lg-4">
                    <recess-input
                        :value="order.customer.birthCountryCode"
                        :label-text="`${PROVIDER.Order.OrderDetails.EditStudentDetails.BirthCountryCode.Label}*`"
                        :error-message="alphabetError('birthCountryCode') || minMaxLengthError('birthCountryCode')"
                        :readonly="!allowEditingOrderStatus"
                        class="qa-order-customer-birth-country-code"
                        @input="(newValue) => setCustomerBirthCode(newValue ? newValue : null)"
                        @blur="$v.order.customer.birthCountryCode.$touch()"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 qa-order-customer-surname">
                    <h5 class="qa-order-customer-surname-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.Surname.Label }}</h5>
                    <p class="qa-order-customer-surname-value">{{ order.customer.surname ? order.customer.surname : '-' }}</p>
                </div>
                <div class="col-lg-4 qa-order-customer-address-house-number-additive">
                    <h5 class="qa-order-customer-address-house-number-additive-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.HouseNumberAdditive.Label }}</h5>
                    <p class="qa-order-customer-address-house-number-additive-value">{{ order.customer.address.houseNumberAdditive ? order.customer.address.houseNumberAdditive : '-' }}</p>
                </div>
                <div class="col-lg-4">
                    <recess-date-picker
                        :value="order.customer.birthDate"
                        :default-value="order.customer.birthDate"
                        :error-message="birthDateError"
                        class="qa-order-customer-birth-date"
                        :readonly="!allowEditingOrderStatus"
                        :label-text="`${PROVIDER.Order.OrderDetails.EditStudentDetails.BirthDate.Label}*`"
                        @input="(newValue) => onCustomerBirthDateChanged(newValue)"
                        date-picker-input-custom-width="170px"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 qa-order-customer-email">
                    <h5 class="qa-order-customer-email-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.Email.Label }}</h5>
                    <p class="qa-order-customer-email-value">{{ order.customer.email ? order.customer.email : '-' }}</p>
                </div>
                <div class="col-lg-4 qa-order-customer-address-postal-code">
                    <h5 class="qa-order-customer-address-postal-code-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.PostalCode.Label }}</h5>
                    <p class="qa-order-customer-address-postal-code-value">{{ order.customer.address.postalCode ? order.customer.address.postalCode : '-' }}</p>
                </div>
                <div class="col-lg-4">
                    <recess-input
                        :value="order.customer.phoneNumber"
                        :label-text="`${PROVIDER.Order.OrderDetails.EditStudentDetails.PhoneNumber.Label}*`"
                        :error-message="phoneNumberError"
                        :readonly="!allowEditingOrderStatus"
                        class="qa-order-customer-phone-number"
                        @input="(newValue) => setCustomerPhoneNumber(newValue)"
                        @blur="$v.order.customer.phoneNumber.$touch()"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 qa-order-customer-company">
                    <h5 class="qa-order-customer-company-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.Company.Label }}</h5>
                    <p class="qa-order-customer-company-value">{{ order.customer.company ? order.customer.company : '-' }}</p>
                </div>
                <div class="col-lg-4 qa-order-customer-address-city">
                    <h5 class="qa-order-customer-address-city-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.City.Label }}</h5>
                    <p class="qa-order-customer-address-city-value">{{ order.customer.address.city ? order.customer.address.city : '-' }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 qa-order-customer-department">
                    <h5 class="qa-order-customer-department-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.Department.Label }}</h5>
                    <p class="qa-order-customer-department-value">{{ order.customer.department ? order.customer.department : '-' }}</p>
                </div>

                <div class="col-lg-4 qa-order-customer-address-country-code">
                    <h5 class="qa-order-customer-address-country-code-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.CountryCode.Label }}</h5>
                    <p class="qa-order-customer-address-country-code-value">{{ order.customer.address.countryCode ? order.customer.address.countryCode : '-' }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 qa-order-customer-external-employee-code">
                    <h5 class="qa-order-customer-external-employee-code-label">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.ExternalEmployeeCode.Label }}</h5>
                    <p class="qa-order-customer-external-employee-code-value">{{ order.externalEmployeeCode ? order.externalEmployeeCode : '-' }}</p>
                </div>
            </div>

            <div class="row mt-5" v-if="allowEditingOrderStatus">
                <div class="col-12 d-flex justify-content-end align-items-center flex-wrap">
                    <recess-button :title="PROVIDER.Shared.Save" type="submit" variant="secondary" class="qa-submit-order-student-details" />
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-11">
                    <h5 class="mb-1">{{ PROVIDER.Order.OrderDetails.EditStudentDetails.TeamLeader.Label }}</h5>
                    <div v-if="teamLeaderInformation">
                        <div class="mb-1 u-text-semi-bold" v-if="order.teamLeaderName">
                            {{ order.teamLeaderName }}
                        </div>
                        <div class="mb-1 u-text-semi-bold" v-if="order.teamLeaderEmailAddress">
                            {{ order.teamLeaderEmailAddress }}
                        </div>
                        <div class="u-text-semi-bold" v-if="order.teamLeaderPhoneNumber">
                            {{ order.teamLeaderPhoneNumber }}
                        </div>
                    </div>
                    <div v-else>
                        <div>-</div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template> 

<script>
import dayjs from 'dayjs'
import { mapState, mapMutations, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { isAllowedAge, isValidDate, isValidDateFormat, isAlpha, isPhoneNumber } from '@/utils/validationHelpers'

import { isRequiredErrorMessage, isInvalidErrorMessage, minMaxLengthErrorMessage, alphabetOnlyError, hasError, isValidIntegerErrorMessage, isValidBirthDateMessage } from '@/../../shared/constants/validationMessageHelper.js'

export default {
    name: 'EditStudentDetailsForm',
    mixins: [validationMixin],
    props: {
        allowEditingOrderStatus: {
            type: Boolean,
            default: false
        }
    },
    validations: {
        order: {
            customer: {
                phoneNumber: { required, isPhoneNumber: isPhoneNumber },
                birthCountryCode: {
                    required,
                    isAlpha,
                    minLength: minLength(2),
                    maxLength: maxLength(3)
                },
                birthDate: {
                    required,
                    isValidDate,
                    isValidDateFormat,
                    isValidBirthDate() {
                        return isAllowedAge(this.order.customer.birthDate, 6, 120)
                    }
                }
            }
        }
    },
    data() {
        return {
            PROVIDER,
            orderId: this.$route.params.id || null
        }
    },
    computed: {
        ...mapState('orderModule', ['order', 'originalOrder']),
        setObjectToBePatched() {
            const patchProperties = ['phoneNumber', 'birthCity', 'birthCountryCode', 'birthDate']
            const array = []

            patchProperties.forEach((property) => {
                const hasAddressChildObject = property.split('/').includes('address')
                const addressProperty = property.split('/')[1]

                if (this.order.customer.address[addressProperty] !== this.originalOrder.customer.address[addressProperty] || this.order.customer[property] !== this.originalOrder.customer[property]) {
                    array.push({
                        value: hasAddressChildObject ? this.order.customer.address[addressProperty] : this.formatDateBeforePatch(property),
                        path: `/customer/${property}`,
                        op: 'replace'
                    })
                }
                if (this.order[property] !== this.originalOrder[property]) {
                    array.push({
                        value: this.order[property],
                        path: `${property}`,
                        op: 'replace'
                    })
                }
            })

            return array
        },
        phoneNumberError() {
            this.$v.$touch()

            return (
                isRequiredErrorMessage('customer', PROVIDER.Order.OrderDetails.EditStudentDetails.PhoneNumber.Label, this.$v.order.customer.phoneNumber) ||
                isValidIntegerErrorMessage(PROVIDER.Order.OrderDetails.EditStudentDetails.PhoneNumber.Label, this.$v.order.customer.phoneNumber, 'isPhoneNumber')
            )
        },
        birthDateError() {
            if (!this.$v.order.customer.birthDate.$error) return null

            return (
                isRequiredErrorMessage('birthDate', PROVIDER.Order.OrderDetails.EditStudentDetails.BirthDate.Label, this.$v.order.customer.birthDate) ||
                isInvalidErrorMessage(PROVIDER.Order.OrderDetails.EditStudentDetails.BirthDate.Label, this.$v.order.customer.birthDate, 'isValidDate' ) ||
                hasError(isValidBirthDateMessage, this.$v.order.customer.birthDate, 'isValidBirthDate') ||
                isInvalidErrorMessage(PROVIDER.Order.OrderDetails.EditStudentDetails.BirthDate.Label, this.$v.order.customer.birthDate, 'isValidDateFormat')
            )
        },
        teamLeaderInformation() {
            return this.order.requireAccountApproval && (this.order.teamLeaderName || this.order.teamLeaderEmailAddress || this.order.teamLeaderPhoneNumber)
        },
        isValidOnSumbit() {
            return !this.$v.$invalid && JSON.stringify(this.order) !== JSON.stringify(this.originalOrder)
        },
        isELearningCourse() {
            return this.order && this.order.learningMethodName === PROVIDER.Order.Shared.LearningMethodName.SelfPlanned
        }
    },
    methods: {
        ...mapActions('orderModule', ['patchOrder', 'getOrder']),
        ...mapMutations('orderModule', ['setCustomerPhoneNumber', 'setCustomerBirthCity', 'setCustomerBirthCode', 'setCustomerBirthDate']),

        onCustomerBirthDateChanged(newValue) {
            this.setCustomerBirthDate(newValue || null)
            this.$v.order.customer.birthDate.$touch()
        },
        formatDateBeforePatch(property) {
            if (property === 'birthDate') {
                return dayjs(this.order.customer[property], 'DD-MM-YYYY').format('YYYY-MM-DD')
            }
            return this.order.customer[property]
        },

        submitHandler() {
            this.$v.$touch()
            if (this.isValidOnSumbit && this.order && this.order.id) {
                this.patchOrder({
                    orderId: this.order.id,
                    objectToBePatched: this.setObjectToBePatched
                })
            }
        },
        alphabetError(property) { 
            return hasError(alphabetOnlyError, this.$v.order.customer[property], 'isAlpha')
        },
        minMaxLengthError(property) {
            return this.$v.order.customer[property].$error && (!this.$v.order.customer[property].minLength || !this.$v.order.customer[property].maxLength || !this.$v.order.customer[property].required)
                ? minMaxLengthErrorMessage('Geboorteland', 2, 3)
                : null
        }
    }
}
</script>


<!-- NOTE: Since this is very specific use case, this code was placed here consciously -->
<style lang="scss">
.c-recess-input__field,
.c-recess-input__date-picker.has-data > input[type='text']:read-only,
.c-recess-input__field:read-only,
.c-recess-input__field--text:read-only {
    color: RGB(var(--r-color-dark-blue));
}
</style>
